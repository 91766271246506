var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('edit-profile', {
    attrs: {
      "isLoading": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "modal",
      fn: function fn() {
        return [_c('sygni-modal', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showModal,
            expression: "showModal"
          }],
          attrs: {
            "isLoading": _vm.isLoading,
            "header": "Do you want to save the changes?"
          },
          on: {
            "cancel": _vm.changeRoute,
            "confirm": _vm.confirmUpdateProfile,
            "close": function close($event) {
              _vm.showModal = false;
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        var _vm$selects;

        return [_c('sygni-container-title', {
          staticClass: "mb-5"
        }, [_vm._v("Detailed data")]), _c('div', {
          staticClass: "edit-profile__form"
        }, [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'MIDDLE NAME'
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.details.middleName,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.details, "middleName", $$v);
            },
            expression: "profileData.details.middleName"
          }
        }), _c('sygni-date-picker', {
          staticClass: "secondary border-collapse",
          attrs: {
            "label": 'BIRTH DATE'
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.details.birthDate,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.details, "birthDate", $$v);
            },
            expression: "profileData.details.birthDate"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'FATHERS NAME'
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.details.fathersName,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.details, "fathersName", $$v);
            },
            expression: "profileData.details.fathersName"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'MOTHERS NAME'
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.details.mothersName,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.details, "mothersName", $$v);
            },
            expression: "profileData.details.mothersName"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'PHONE NO.',
            "validation": _vm.$v.profileData.generalData.phone
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "phone", $$v);
            },
            expression: "profileData.generalData.phone"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'ID NO.',
            "validation": _vm.$v.profileData.details.identificationNumber
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.details.identificationNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.details, "identificationNumber", $$v);
            },
            expression: "profileData.details.identificationNumber"
          }
        }), _c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "label": 'DOCUMENT TYPE',
            "options": (_vm$selects = _vm.selects) !== null && _vm$selects !== void 0 && _vm$selects.documentType ? _vm.selects.documentType : []
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.details.documentType,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.details, "documentType", $$v);
            },
            expression: "profileData.details.documentType"
          }
        }), _c('sygni-input', {
          staticClass: "secondary border-collapse",
          attrs: {
            "label": 'DOCUMENT NUMBER'
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.details.documentNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.details, "documentNumber", $$v);
            },
            expression: "profileData.details.documentNumber"
          }
        }), _c('div', {
          staticClass: "expiry-date"
        }, [_c('div', {
          staticClass: "expiry-date__checkbox"
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "label": "INDEFINITELY"
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.details.documentExpiryIndefinitely,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.details, "documentExpiryIndefinitely", $$v);
            },
            expression: "profileData.details.documentExpiryIndefinitely"
          }
        })], 1), _c('sygni-date-picker', {
          staticClass: "secondary border-collapse",
          attrs: {
            "label": 'DOCUMENT EXPIRY DATE',
            "disabled": _vm.isDocumentExpiryFieldDisabled
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.details.documentExpiryDate,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.details, "documentExpiryDate", $$v);
            },
            expression: "profileData.details.documentExpiryDate"
          }
        })], 1), _c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "options": _vm.globalSelects.countries,
            "label": 'DOCUMENT COUNTRY',
            "placeholder": 'Choose',
            "searchable": true
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.details.documentCountry,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.details, "documentCountry", $$v);
            },
            expression: "profileData.details.documentCountry"
          }
        }), _c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "options": _vm.globalSelects.countries,
            "options-label": 'label',
            "label": 'COUNTRY OF TAX RESIDENCE',
            "placeholder": 'Choose',
            "searchable": true
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.details.country,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.details, "country", $$v);
            },
            expression: "profileData.details.country"
          }
        }), _c('sygni-rounded-button', {
          class: ['filled gn-primary button secondary mt-4', !_vm.hasChanges ? 'inactive' : ''],
          on: {
            "click": function click($event) {
              return _vm.updateProfile(_vm.nextRoute);
            }
          }
        }, [_vm._v("Save changes")])], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }